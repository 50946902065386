<template>
  <div class="inventory-popup" style="display: inline-block">
    <a-popconfirm
      placement="topLeft"
      class="popver"
      @confirm="submit"
      ok-text="确认"
      cancel-text="取消"
    >
      <a-icon slot="icon" style="display: none"></a-icon>
      <template slot="title">
        <div>
          <div class="skuInfo">
            <img class="image" :src="getPhoto(record.picDef)" />
            <div class="product-name">
              <p>{{ record.name }}</p>
            </div>
          </div>
          <div class="editQuantity">
            <div class="visibleStock">
              <ul class="stock-oul">
                <li class="image-c">
                  套餐图片
                </li>
                <li class="name-c">
                  套餐名称
                </li>
                <!--  <li class="incoming-c">
                  Incoming
                </li> -->
                <li class="num-c">
                  现有库存
                </li>
                <li class="num-c">
                  编辑后库存
                </li>
                <li class="edit-c">
                  库存编辑
                </li>
              </ul>
              <div
                class="stock-operation"
                v-for="(item, index) in spaces"
                :key="index"
              >
                <img class="s-image" :src="getPhoto(item.picDef)" />
                <div class="s-name">
                  <p>{{ item.name }}</p>
                </div>
                <div class="s-num">
                  <span>{{ item.num }}</span>
                </div>
                <div class="s-num">
                  <span>{{ item.result }}</span>
                </div>
                <div class="edit-stock">
                  <div class="radio">
                    <a-radio-group
                      v-model="item.operation"
                      button-style="solid"
                      @change="operationChange(index)"
                    >
                      <a-radio-button value="add">
                        增加
                      </a-radio-button>
                      <a-radio-button value="set">
                        设置
                      </a-radio-button>
                    </a-radio-group>
                  </div>
                  <a-input-number
                    class="input"
                    :min="item.operation === 'set' ? 0 : -item.num"
                    v-model="item.value"
                    @change="valueChange(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <a class="mr10" href="javascript:;">库存</a>
    </a-popconfirm>
  </div>
</template>

<script>
import { changeInventory } from "@/serve/product";
import global from "global";

export default {
  props: {
    record: Object
  },
  data() {
    return {
      // 规格数组对象
      spaces: []
    };
  },
  created() {
    this.update();
  },
  methods: {
    update() {
      this.spaces = [];
      this.record.spaces.forEach(item => {
        this.spaces.push({
          id: item.id,
          picDef: item.picDef,
          name: item.properties[0].value,
          num: item.num,
          operation: "add",
          value: 0,
          result: item.num
        });
      });
    },
    getPhoto(id) {
      if (!id) {
        return false;
      } else if (id.indexOf(",") > -1) {
        let reg = new RegExp(",");
        let ids = id.replace(reg, "");
        return global.file.url + "/sys/fs/" + ids + "?s=1";
      } else {
        return global.file.url + "/sys/fs/" + id + "?s=1";
      }
    },
    operationChange(index) {
      if (this.spaces[index].operation === "add") {
        this.spaces[index].result =
          this.spaces[index].num + this.spaces[index].value;
      } else {
        this.spaces[index].result = this.spaces[index].value;
      }
    },
    valueChange(index) {
      if (this.spaces[index].operation === "add") {
        this.spaces[index].result =
          this.spaces[index].num + this.spaces[index].value;
      } else {
        this.spaces[index].result = this.spaces[index].value;
      }
    },
    submit() {
      let params = [];
      this.spaces.forEach(item => {
        params.push({
          num: item.result,
          id: item.id
        }); 
      });
      changeInventory(params)
        .then(res => {
          this.$emit("update");
          this.$message.success("库存修改成功");
        })
        .catch(() => {
          this.$message.error("库存修改失败");
        });
    }
  },
  watch: {
    "$props.record": {
      handler(newVal) {
        this.update();
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="less">
.store-field-editing {
  margin-top: 30px;
  position: relative;
  .buttons {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .ant-btn {
      margin-right: 15px;
    }
  }
  .ant-form-item {
    width: 100%;
  }
  .ant-form-item-control-wrapper {
    width: 60%;
  }
}
</style>
<style lang="less">
.ant-popover-inner {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
}
.visibleStock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  ul {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    li {
      text-align: center;
      &.name-c,
      &.num-c {
        flex: 1;
      }
      &.image-c {
        flex: 0.5;
      }
      &.edit-c {
        flex: 2;
      }
    }
  }
  .stock-operation {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 10px 5px;
    .s-name,
    .s-num {
      flex: 1;
      font-size: 16px;
      text-align: center;
    }
    .s-image {
      flex: 0.5;
    }

    .edit-stock {
      flex: 2;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .input {
        width: 30%;
      }
      .textarea {
        width: 50%;
      }
      /* /deep/ textarea.ant-input {
               line-height: 1;
            } */
    }
  }
}
.ant-popover {
  width: 55%;
  .skuInfo {
    display: flex;
    margin-top: 15px;
    p {
      word-break: break-all;
      margin-bottom: 0;
    }
    .product-name {
      width: 200px;
      margin: 0 20px;
    }
    .image {
      width: 50px;
      height: 50px;
    }
  }
  // /deep/ .ant-popover-inner-content {
  //   padding-bottom: 10px;
  //   position: relative;
  //   /deep/ .ant-popover-buttons {
  //     display: none;
  //   }
  //   /deep/ .ant-popover-message {
  //   }
  // }
}
</style>
