import request from '@/utils/request'

const addProduct = (params) => {
  return request({
    url: '/sell/products',
    data: params,
    method: 'post'
  })
}

const modifyProduct = (id, params) => {
  return request({
    url: `/sell/products/${id}`,
    data: params,
    method: 'put'
  })
}

const getProductDetail = (id) => {
  return request({
    url: `/sell/products/${id}`,
    method: 'get'
  })
}

const getProductList = (params) => {
  return request({
    url: '/sell/products',
    params: params,
    method: 'get'
  })
}

const getProductCount = (params) => {
  return request({
    url: '/sell/products/count',
    params: params,
    method: 'get'
  })
}
const changeInventory = (params) => {
  return request({
    url: `/sell/products/batch/put/num`,
    data: params,
    method: 'post'
  })
}
const changeState = (id, params) => {
  return request({
    url: `/sell/products/${id}/shelves`,
    data: params,
    method: 'put'
  })
}

const deleteProduct = (params) => {
  return request({
    url: `/sell/products/${params}`,
    params: params,
    method: 'delete'
  })
}
export {
  addProduct, // 添加商品
  modifyProduct, // 修改商品详情
  getProductDetail, // 获取商品详情
  getProductList, // 获取商品列表
  getProductCount, // 获取商品数量
  changeState, // 上下架状态
  deleteProduct, // 删除商品
  changeInventory, //修改库存
}
