import request from '@/utils/request'

const getCategory = (idParent) => {
  return request({
    url: '/open/categories',
    params: idParent,
    method: 'get'
  })
}

const getCategoryDetail = (id) => {
  return request({
    url: `/open/categories/${id}`,
    method: 'get'
  })
}

export {
  getCategory, // 获取分类列表信息
  getCategoryDetail // 分类详情
}
