<template>
  <div class="goods-list">
    <div class="table-title">
      <p class="title">商品管理</p>
      <p class="total">共{{ total }}条</p>
    </div>
    <div class="table-menu">
      <div class="table-menu-left">
        <label for="select">商品状态：</label>
        <a-select defaultValue style="width: 120px;height:32px" @change="handleChange">
          <a-select-option value>全部</a-select-option>
          <a-select-option value="down">未上架</a-select-option>
          <a-select-option value="up">上架中</a-select-option>
          <!-- <a-select-option value="verb">缺货</a-select-option> -->
        </a-select>
        <!-- <a-button type="primary" style="background-color:rgb(244,86,93)">上架商品</a-button> -->
      </div>
      <div class="table-menu-right">
        <a-input-search placeholder="请输入商品关键字" @search="onSearch" v-model="keyword" enterButton="查找"
          >查找</a-input-search
        >
      </div>
    </div>
    <div class="my-table">
      <a-table :columns="columns" :loading="loading" :dataSource="data" align="center" rowKey="id" :pagination="false">
        <template slot="name" slot-scope="name, record">
          <div class="price-box orders-box" style="display: flex;">
            <div>
              <img :src="getPhoto(record.picDef)" width="50px" />
            </div>
            <div class="ml10">
              <!-- <router-link :to="`/preview/${record.sku}`" target="_blank">
                <p>{{record.name}}</p>
              </router-link>-->
              <span>{{ record.name }}</span>
              <p>{{ record.tag }}</p>
            </div>
            <div class="msg-f dp-hide">
              <p v-for="(item, index) in record.spaces" :key="index">
                套餐：{{ item.properties[0].name }}/{{
                  item.properties[0].value
                }}，库存：{{ item.num }}，价格：{{
                  item.unitPrice
                }}，供应商协议价：{{ item.unitPriceCost }}
              </p>
            </div>
          </div>
        </template>
        <template slot="categoryId" slot-scope="text">{{
          getCategoryName(text)
        }}</template>
        <template slot="shoperType" slot-scope="shoperType, record">{{
          getShoperType(record.shoperType)
        }}</template>
        <template slot="blocTagBlocId" slot-scope="blocTagBlocId, record">{{
          getBlocType(record.blocTagBlocId,record.ageStage)
        }}</template>
        <template slot="ageStage" slot-scope="ageStage, record">{{
          getAgeStage(record.ageStage)
        }}</template>
        <span class="price-box" slot="unitPrice" slot-scope="unitPrice, record">
          <del>
            <p class="price" v-if="record.unitPriceShow">
              ￥{{ record.unitPriceShow }}
            </p>
          </del>
          <span class="price-show">￥{{ record.unitPrice }}</span>
        </span>
        <span class="shop-state" slot="state" slot-scope="state, record">
          <!-- <span v-if="isShow(record.state, record.upAllow)">
            <a-tag color="cyan">上架中</a-tag>
          </span>
          <span v-if="isShow(record.state, record.upAllow)">
            <a-tag color="orange">未上架</a-tag>
          </span> -->
          <span>
            <a-tag :color="isColor(record.state, record.upAllow)">{{
              isShowStateText(record.state, record.upAllow)
            }}</a-tag>
          </span>
          <span v-if="getStats(record.spaces)">
            <a-tag color="red">缺货</a-tag>
          </span>
        </span>  
        <span slot="action" slot-scope="action, record" class="operation">
          <InventoryPopover :record="record" @update="update"/>
          <a
            class="mr10"
            v-show="isShow(record.state, record.upAllow)"
            href="javascript:;"
            @click="PutOff(record.id, 'DOWN')"
            >下架</a
          >
          <a
            class="mr10"
            v-show="!isShow(record.state, record.upAllow)"
            href="javascript:;"
            :class="{ isNone: isDisabled(record.state, record.upAllow) }"
            @click="PutOff(record.id, 'UP')"
            >上架</a
          >
          <router-link
            :to="`/detail/${record.id}`"
            v-show="!isShow(record.state, record.upAllow)"
            class="mr10"
            >编辑</router-link
          >
          <a
            v-show="!isShow(record.state, record.upAllow)"
            href="javascript:;"
            @click="DeletePro(record.id)"
            >删除</a
          >
        </span>
      </a-table>
      <div style="text-align: right;" class="mt10 mb10 mr10">
        <a-pagination
          v-model="current"
          :total="total"
          :defaultPageSize="20"
          @change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getProductList,
  changeState,
  deleteProduct,
  getProductCount
} from "../../serve/product";
import { getCategory } from "@/serve/category";
// getCategoryDetail
import InventoryPopover from "@/components/InventoryPopover";
import global from "global";

const columns = [
  {
    dataIndex: "name",
    key: "name",
    title: "商品",
    width: "15%",
    scopedSlots: { customRender: "name" }
  },
  {
    dataIndex: "categoryId",
    key: "categoryId",
    title: "商品类型",
    scopedSlots: { customRender: "categoryId" }
  },
  {
    key: "shoperType",
    title: "买方类型",
    scopedSlots: { customRender: "shoperType" }
  },
  {
    key: "blocTagblocType",
    title: "供货类型",
    scopedSlots: { customRender: "blocTagBlocId" }
  },
  {
    key: "ageStage",
    title: "年龄层次",
    scopedSlots: { customRender: "ageStage" }
  },
  {
    title: "初始价格",
    dataIndex: "unitPrice",
    align: "right",
    scopedSlots: { customRender: "unitPrice" }
  },
  // }, {
  //   title: '规格/包装',
  //   dataIndex: 'address',
  //   key: 'address'
  // }, {
  {
    title: "商品状态",
    dataIndex: "state",
    scopedSlots: { customRender: "state" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  name: "goodsList",
  components: { InventoryPopover },
  data() {
    return {
      data: [],
      columns,
      total: 0,
      keyword: "",
      loading: false,
      params: {
        sort: "create_time desc",
        status: "",
        page: 0,
        rows: 20
      },
      categoryList: [],
      categoryName: "",
      current: 1,
      color: ""
    };
  },
  methods: {
    // 分页
    pageChange(page) {
      this.params.page = page - 1;
      this.getList(this.params);
    },
    handleChange(val) {
      if (val) {
        this.params.status = val;
      } else {
        delete this.params.status;
      }
      this.params.keyword = this.keyword;
      this.getList(this.params);
    },
    async onSearch() {
      this.params.page = 0;
      this.params.keyword = this.keyword;
      this.getList(this.params);
      this.total = this.data.length;
    },
    isShowStateText(state, upAllow) {
      if (state === 100 && upAllow) {
        return "上架中";
      } else if ((state === 0 && !upAllow) || (state === -100 && !upAllow)) {
        return "未上架";
      } else if (state === 0 && upAllow) {
        return "上架审核中";
      }
    },
    isColor(state, upAllow) {
      if (state === 100 && upAllow) {
        return "cyan";
      } else if ((state === 0 && !upAllow) || (state === -100 && !upAllow)) {
        return "orange";
      } else if (state === 0 && upAllow) {
        return "purple";
      }
    },
    isShow(state, upAllow) {
      if (state === 100 && upAllow) {
        return true;
      } else if ((state === 0 && !upAllow) || (state === -100 && !upAllow)) {
        return false;
      } else if (state === 0 && upAllow) {
        return false;
      }
    },
    isDisabled(state, upAllow) {
      if (state === 0 && upAllow) {
        return true;
      }
      return false;
    },
    // 获取图片
    getPhoto(id) {
      if (!id) {
        return false;
      } else if (id.indexOf(",") > -1) {
        let reg = new RegExp(",");
        let ids = id.replace(reg, "");
        return global.file.url + "/sys/fs/" + ids + "?s=1";
      } else {
        return global.file.url + "/sys/fs/" + id + "?s=1";
      }
    },
    async getList(params) {
      this.loading = true;
      this.total = await getProductCount(params);
      this.total > 0
        ? (this.data = await getProductList(params))
        : (this.data = []);
      this.loading = false;
    },

    update() {
      this.getList(this.params);
    },

    PutOff(id, type) {
      changeState(id, { operate: type })
        .then(res => {
          this.$message.success(type === "UP" ? "上架审核中" : "下架成功");
          this.getList(this.params);
        })
        .catch(err => {
          this.$message.error(err);
        });
    },
    DeletePro(id) {
      this.$confirm({
        title: "提示",
        content: "真的要删除吗 ?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          deleteProduct(id)
            .then(() => {
              console.log("删除成功", this);
              this.getList(this.params);
              this.$message.success("删除成功");
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        },
        onCancel() {}
      });
    },

    getCategoryName(id) {
      for (let i = 0; i < this.categoryList.length; i++) {
        if (this.categoryList[i].id === id) {
          if (this.categoryList[i].name.indexOf("主题") !== -1) {
            return "幼儿园美工材料包/" + this.categoryList[i].name;
          } else if (this.categoryList[i].name.indexOf("主题") === -1) {
            return this.categoryList[i].name;
          }
        }
      }
    },
    // 获取库存状态
    getStats(params) {
      let k = "";
      for (let i = 0; i < params.length; i++) {
        if (params[i].num === 0) {
          k += "缺货";
        } else {
          k += "2";
        }
      }
      if (k.indexOf("缺货") === -1) {
        return false;
      } else {
        return true;
      }
    },
    // 获取买方类型
    getShoperType(shoperType) {
      let shoperList = shoperType.split(",");
      let shoperTypeList = [];
      for (let i = 0; i < shoperList.length; i++) {
        if (shoperList[i] === "TEACHER") {
          shoperTypeList.push("教师");
        } else if (shoperList[i] === "STUDENT") {
          shoperTypeList.push("学生及家长");
        }else if(shoperList[i] === "OTHER"){
          shoperTypeList.push("外部");
        }
      }
      return shoperTypeList.join(",");
    },
    // 获取供货类型
    getBlocType(blocType,ageStage) {
      let BlocList = blocType.split(",");
      let ageList = ageStage.split(",");
      let BlocTypeList = [];
      for (let i = 0; i < BlocList.length; i++) {
        if (BlocList[i] === "adminid") {
          BlocTypeList.push("海富园");
        } else if (BlocList[i] === "jyy") {
          BlocTypeList.push("精英园");
        } else if (BlocList[i] === "other") {
          BlocTypeList.push("外部园");
        }
      }
      if(ageList && !ageList.includes('KG')){//判断年龄层次为中小学
        BlocTypeList.push("中小学");//添加供货方中小学
      }
      return BlocTypeList.join(",");
    },
    // 获取年龄层次
    getAgeStage(ageStage) {
      let ageList = ageStage.split(",");
      let ageStageList = [];
      for (let i = 0; i < ageList.length; i++) {
        if (ageList[i] === "KG") {
          ageStageList.push("幼儿园");
        } else if (ageList[i] === "PS") {
          ageStageList.push("小学");
        } else if (ageList[i] === "JS") {
          ageStageList.push("初中");
        } else if (ageList[i] === "HS") {
          ageStageList.push("高中");
        }
      }
      return ageStageList.join(",");
    },
    // 获取分类信息
    getCategory() {
      getCategory({ idParent: "0" }).then(res => {
        console.log(res);
        this.categoryList = res;
        res.forEach(item => {
          item.value = item.id;
          item.label = item.name;
          if (item.childrenNum > 0) {
            getCategory({ idParent: item.id }).then(res1 => {
              res1.forEach(item1 => {
                this.categoryList.push(item1);
              });
              // item.children = res1
            });
          }
        });
        // this.categoryList = res
        // this.options = res
      });
    }
  },
  created() {
    this.getList(this.params);
    this.getCategory();
  }
};
</script>

<style lang="less" scoped>
.goods-list {
  border: 1px solid #cccccc;
  .table-title {
    .title {
      font-weight: 600;
      color: black;
      font-size: 15px;
      margin: 10px 0 10px 10px;
    }
    .total {
      text-align: right;
      margin: 10px 10px 10px 10px;
    }
  }
}
.table-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 20px;
  .table-menu-right {
    // height: 32px;
    .my-input {
      height: 32px !important;
    }
  }
}
.my-table {
  .price-box {
    // display: flex;
    // flex-direction: column;
    .price-show {
      color: red;
    }
  }
}
.editable-row-operations a {
  margin-right: 8px;
}
.a-margin {
  margin-left: 5px;
}
.shop-state {
  display: flex;
  flex-direction: column;
  align-content: space-around !important;
  text-align: center;
}
.operation {
  .isNone {
    display: none;
  }
}
.orders-box:hover .msg-f {
  display: block;
  z-index: 99;
}
.orders-box {
  position: relative;
  border: none;
}
//  .msg-f {
//   width: 200px;
//   line-height: 28px;
//   padding: 2px 10px;
//   background: #fff;
//   border: 1px solid #ddd;
//   border-radius: 3px;
//   box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
//   position: absolute;
//   top: 121px;
//   left: 3%;
//   margin-left: -71px;
//   z-index: 99;
// }
//  .msg-f:before {
//   content: "";
//   position: absolute;
//   top: -9px;
//   left: 65px;
//   display: inline-block;
//   border-right: 9px solid transparent;
//   border-bottom: 9px solid #ddd;
//   border-left: 9px solid transparent;
// }
//  .msg-f:after {
//   content: "";
//   position: absolute;
//   top: -8px;
//   left: 65px;
//   display: inline-block;
//   border-right: 9px solid transparent;
//   border-bottom: 9px solid #fff;
//   border-left: 9px solid transparent;
// }
.orders-box .msg-f {
  width: 450px;
  text-align: left;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -18px;
  left: 60px;
}
.orders-box .msg-f:before {
  content: "";
  position: absolute;
  top: 19px;
  right: 249px;
  display: inline-block;
  border-top: 9px solid transparent;
  border-right: 9px solid #ddd;
  border-bottom: 9px solid transparent;
}
.orders-box .msg-f:after {
  content: "";
  position: absolute;
  top: 19px;
  right: 248px;
  display: inline-block;
  border-top: 9px solid transparent;
  border-right: 9px solid #fff;
  border-bottom: 9px solid transparent;
}
.orders-box .msg-f p {
  margin-bottom: 1em;
}
</style>
